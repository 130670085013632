import React, { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "../styleComponents/navStyle.css";
import logo from "../assets/logo.png";
import IconsStack from "./iconsStack";
import { handleNavClick } from "../services/scrollToPage";
import { navigationItems } from "../consts/pagesConsts";

const NavBar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      dir="rtl"
      expanded={expanded}
    >
      <Container fluid style={{ width: "90%" }}>
        <Navbar.Brand href="#"><img
          width="50"
          height="50"
          src={logo}
          alt="logo"
        /></Navbar.Brand>


        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />

        <Navbar.Collapse id="collapse" >
          <Nav navbarScroll onClick={() => setExpanded(!expanded)}
          >
            {navigationItems.map((page) => (
              <Nav.Link
                style={{ justifyContent: "center", display: "flex" }}
                onClick={() => handleNavClick(page.path)}
                key={page.path}
                id="link"
              >
                <label id="title">{page.title}</label>
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        <IconsStack color="#be9827" width="25px" />
      </Container>
    </Navbar>
  );
};

export default NavBar;
