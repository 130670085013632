import styled from "styled-components";

const StyledButton = styled.button<{ color: string, colorHover: string }>`
  background-color: ${({ color }) => color};
  color: rgb(0, 0, 0);
  padding: 8px 15px;
  border: 2px solid #be9827;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ colorHover }) => colorHover};
  }
`

export default StyledButton;