import React from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import styled from "styled-components";
import ComponentSpacer from "./componentSpacer";

const StyledTitle = styled.span`
  color: #be9827;
`;

const InViewCountUp: React.FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div>
      <ComponentSpacer space={1} />
      <p ref={ref} className="text-center">
        <StyledTitle style={{ fontSize: "2em" }}>
          למעלה מ<a> </a>{" "}
          {inView && (
            <CountUp start={0} end={3000} duration={3.5} separator="," />
          )}{" "}
          <a> </a> לקוחות מרוצים!
        </StyledTitle>
      </p>
    </div>
  );
};

export default InViewCountUp;
