import React, { useState } from "react";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import "../styleComponents/contactStyle.css";
import emailjs from "emailjs-com";
import StyledTitle from "../components/styledTitle";
import ComponentSpacer from "../components/componentSpacer";
import Alert from "react-bootstrap/Alert";
import { Spinner } from "react-bootstrap";
import {
  cleanErrMessage,
  translateErrMsg,
} from "../services/translateFormMessages";
import StyledButton from "../styleComponents/styled";

const Contact: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault();

    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setLoader(true);
      setTimeout(() => { }, 2000);
      const publicKey = "5sP8r6N-bvaRCUKLE";
      const templateId = "template_11us0ck";
      const serviceId = "service_a9xmfid";
      emailjs.sendForm(serviceId, templateId, e.target, publicKey).then(
        (result: any) => {
          setLoader(false);
          setSuccess(true);
          window.setTimeout(() => {
            setSuccess(false);
          }, 5000);
        },
        (error: any) => {
          setLoader(false);
          setError(true);
          window.setTimeout(() => {
            setError(false);
          }, 5000);
        },
      );
      e.target.reset();
    }
  };

  return (
    <div>
      <StyledTitle text={"יצירת קשר"} />
      <ComponentSpacer space={1} />
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Card id="card">
          <Form validated={false} onSubmit={sendEmail} id="form">
            <Row className="mb-4" id="formRow">
              <Form.Group as={Col} controlId="validationCustom01">
                <Form.Label>
                  {" "}
                  שם מלא <label className="required">*</label>
                </Form.Label>
                <Form.Control
                  required
                  onInvalid={translateErrMsg}
                  onInput={cleanErrMessage}
                  type="text"
                  placeholder="שם מלא"
                  id="field"
                  name="name"
                />
                <Form.Control.Feedback>מעולה!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  הכנס בבקשה שם מלא
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group as={Col} controlId="validationCustom03">
              <Form.Label>טלפון (לא חובה)</Form.Label>
              <Form.Control
                dir="ltr"
                type="tel"
                placeholder="05X-XXXXXXX"
                id="field"
                name="phone"
              />
              <Form.Control.Feedback>מעולה!</Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-4" id="formRow"></Row>
            <Row className="mb-4" id="formRow">
              <Form.Group as={Col} controlId="validationCustom02">
                <Form.Label>
                  {" "}
                  דואר אלקטרוני <label className="required">*</label>
                </Form.Label>
                <Form.Control
                  id="field"
                  onInvalid={translateErrMsg}
                  onInput={cleanErrMessage}
                  dir="ltr"
                  required
                  type="email"
                  placeholder="XXX@XXX.XXX"
                  name="email"
                />
                <Form.Control.Feedback>מעולה!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  הכנס בבקשה דואר אלקטרוני תקין
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4" id="msgInput">
              <Form.Group className="mb-3" controlId="validationCustom04">
                <Form.Label>
                  {" "}
                  כיצד נוכל לעזור לך? <label className="required">*</label>
                </Form.Label>
                <Form.Control
                  required
                  onInvalid={translateErrMsg}
                  onInput={cleanErrMessage}
                  as="textarea"
                  rows={2}
                  name="message"
                  placeholder="אשמח לקבל הצעת מחיר עבור..."
                  id="field"
                />
                <p style={{ fontSize: '0.75rem' }}>
                  השאירו פרטים ונחזור אליכם במהירות!
                </p>
                <Form.Control.Feedback>מעולה!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  מלא בבקשה את ההודעה
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {error && (
              <Alert style={{ justifyContent: 'center', display: 'flex' }} variant="danger"
              >ארעה שגיאה בשליחת הפנייה, אנא נסה שוב !</Alert>
            )}
            {success && (
              <Alert style={{ justifyContent: 'center', display: 'flex', textAlign: 'center' }} variant="success">
                הפנייה נשלחה בהצלחה. נחזור אלייך בהקדם האפשרי !
              </Alert>
            )}
            <div id="divBtn">
              <StyledButton color="#fffcf4" colorHover="#be9827" type="submit" style={{ width: '35%' }}>
                {loader ? (
                  <Spinner
                    style={{ marginTop: "5px" }}
                    animation="border"
                    role="status"
                  ></Spinner>
                ) : (
                  "שליחה"
                )}
              </StyledButton>
            </div>
          </Form>
        </Card>
      </div>

    </div>
  );
};

export default Contact;
