import React from "react";
import styled from "styled-components";

const StyledTitle: React.FC<{ text: string }> = ({ text }) => {
  return (
    <StyledTitleWrapper>
      <StyledText>{text}</StyledText>
    </StyledTitleWrapper>
  );
};

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledText = styled.h1`
  color: #be9827;
`;

export default StyledTitle;
