import React from "react";
import { Stack } from "react-bootstrap";
import Icon from "./icon";
import { BsWhatsapp, BsTelephone } from "react-icons/bs";

type IconsStackProps = {
    color: string;
    width: string;
};

const IconsStack: React.FC<IconsStackProps> = ({ color, width }) => {
    return (
        <Stack style={{ justifyContent: 'center' }} className="d-flex" direction="horizontal" gap={3}>
            <Icon
                IconType={BsWhatsapp}
                onClick={() =>
                    window.open(
                        "https://api.whatsapp.com/send/?phone=972524744470&text=היי%2C+%0A+אני+פונה+בקשר+לשיפוץ+תיבת+הדואר+שלי - &type=phone_number&app_absent=0",
                        "_blank",
                    )
                }
                text={"שלחו הודעה"}
                color={color} 
                width={width} 
            />
            <div className="vr" id="line" />
            <Icon
                IconType={BsTelephone}
                onClick={() => window.open("tel:0524744470", "_blank")}
                text={"052-4744470"}
                color={color} 
                width={width} 
            />
        </Stack>
    );
};

export default IconsStack;