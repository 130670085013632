import React from "react";
import AboutCard from "../components/aboutCard";
import InViewCountUp from "../components/countUpTitle";
import StyledButton from "../styleComponents/styled";
import { handleNavClick } from "../services/scrollToPage";
import { Paths } from "../consts/pagesConsts";

const About: React.FC = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <InViewCountUp />
      <StyledButton color="#be9827" colorHover="#fffcf4" style={{ width: '15rem' }}
        onClick={() => handleNavClick(Paths.CONTACT)}>ליצירת קשר</StyledButton>
      <AboutCard />
    </div>
  )
}

export default About;