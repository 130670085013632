export const translateErrMsg = (e: any) => {
  switch (e.target.name) {
    case "name":
      e.target.setCustomValidity("שם מלא חובה");
      break;
    case "email":
      e.target.setCustomValidity("אימייל לא תקין");
      break;
    case "message":
      e.target.setCustomValidity("שדה זה הוא חובה");
      break;
    default:
      e.target.setCustomValidity("");
  }
};

export const cleanErrMessage = (e: any) => {
  e.target.setCustomValidity("");
};
