import "./App.css";
import NavBar from "./components/navBar";
import Catalog from "./pages/catalog";
import Contact from "./pages/contact";
import About from "./pages/about";
import React, { Component } from "react";
import Main from "./pages/main";
import styled from "styled-components";
import Footer from "./components/footer";

export default class App extends Component {
  render() {
    return (
      <div id="app">
        <div>
          <NavBar />
        </div>
        <div dir="rtl">
          <div id="main">
            <Main />
          </div>
          <BoldComponent id="about">
            <About />
          </BoldComponent>
          <div id="catalog">
            <Catalog />
          </div>
          <BoldComponent className="p-3" id="contact">
            <Contact />
          </BoldComponent>
          <Footer />
        </div>
      </div>
    );
  }
}

const BoldComponent = styled.div`
  background-color: #dda9220d;
`;
