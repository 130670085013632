import React, { useEffect, useState } from "react";
import { Container, Card, Col, Row } from "react-bootstrap";
import ImgModal from "../components/imgModal";
import Title from "../components/styledTitle";
import {
  fetchImagesData,
  ImagesData,
} from "../services/fetchImagesData";
import ComponentSpacer from "../components/componentSpacer";
import "../styleComponents/catalogStyle.css";
import { AiOutlineZoomIn } from 'react-icons/ai'; // Example: using react-icons for magnifying glass icon
import AllCatalogModal from "../components/allCatalogModal";
import StyledButton from "../styleComponents/styled";

const Catalog: React.FC = () => {
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imagesData, setImagesData] = useState<ImagesData>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchImagesData();
        setImagesData(data);
      } catch (error) {
        alert("שגיאה בקבלת המידע");
      }
    };

    fetchData();
  }, []);

  const handleSeeMore = () => {
    setShowMoreModal(true);
  };

  const handleCloseMoreModal = () => {
    setShowMoreModal(false);
  };

  const handleOpenImageModal = (image: string) => {
    setShowImageModal(true);
    setSelectedImage(image);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };



  return (
    <div data-aos="fade-up">
      <ComponentSpacer space={1} />
      <Title text={"גלריית העבודות שלנו"} />
      <div id="fullImg"></div>
      <Container style={{ textAlign: "center" }}>
        <Row xs={1} lg={3} className="g-3">
          {imagesData?.imgsForPage.map((item, index) => (
            <Col key={index}>
              <Card
                id="cardCatalog"
                className="enlarge-image"
                onClick={() => handleOpenImageModal(item.img)}
              >
                <Card.Img
                  variant="top"
                  src={require(`../assets/images/${item.img}`)}
                  id="img"
                />
                <div className="position-absolute top-0 start-0 p-2">
                  <AiOutlineZoomIn size={35} color="black" />
                </div>
                <Card.Body id="bodyCardCatalog">
                  <Card.Title id="titleCardCatalog">{item.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <StyledButton color="#fffcf4" colorHover="#be9827" style={{marginTop: '1.5rem'}}
          onClick={handleSeeMore}
        >
          לתמונות נוספות
        </StyledButton>
        <ComponentSpacer space={1} />

        {imagesData?.allImages &&
          <AllCatalogModal
            allImages={imagesData.allImages}
            handleOpenImageModal={handleOpenImageModal}
            handleCloseMoreModal={handleCloseMoreModal}
            showMoreModal={showMoreModal}
          />
        }
        <ImgModal
          isOpen={showImageModal}
          onClose={handleCloseImageModal}
          imageUrl={selectedImage ?? ""}
        />
      </Container>
    </div>
  );
};

export default Catalog;
