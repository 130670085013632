import jsonData from "../imagesData.json";

export interface ImgItem {
  img: any;
  title: string;
}

export interface ImagesData {
  imgsForPage: ImgItem[];
  allImages: ImgItem[];
}

export const fetchImagesData = async (): Promise<ImagesData> => {
  return new Promise<ImagesData>((resolve, reject) => {
    setTimeout(() => {
      resolve(jsonData);
    }, 1); //  delay to simulate fetch
  });
};
