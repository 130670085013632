import React from "react";

type ImgModalProps = {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
};

const ImgModal: React.FC<ImgModalProps> = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div style={styles.overlay as React.CSSProperties} onClick={onClose}>
      <div
        style={styles.content as React.CSSProperties}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          style={styles.closeButton as React.CSSProperties}
          onClick={onClose}
        >
          x
        </button>
        <img
          src={require(`../assets/images/${imageUrl}`)}
          alt="Modal"
          style={styles.image}
        />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1056,
  },
  content: {
    position: "relative",
    overflowY: "auto",
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.8)",
    borderRadius: "8px",
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "90vh",
    width: "auto",
    margin: "0 auto",
    display: "block",
  },
  closeButton: {
    position: "absolute",
    top: "-1.5%",
    right: "0px",
    fontSize: "25px",
    background: "transparent",
    border: "none",
    color: "#000",
    cursor: "pointer",
  },
};

export default ImgModal;
