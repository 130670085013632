enum Paths {
    MAIN = "main",
    ABOUT = "about",
    CATALOG = "catalog",
    // OVERVIEW = "overview",
    CONTACT = "contact",
}

const navigationItems = [
    { path: Paths.MAIN, title: "ראשי" },
    { path: Paths.ABOUT, title: "עלינו" },
    { path: Paths.CATALOG, title: "קטלוג" },
    // { path: Paths.OVERVIEW, title: "ביקורות" },
    { path: Paths.CONTACT, title: "יצירת קשר" },
];

export { Paths, navigationItems };
