import React, { useState } from "react";
import styled from "styled-components";

type IconProps = {
    text: string;
    onClick: () => void;
    IconType: any;
    color: string;
    width: string;
};

const Icon: React.FC<IconProps> = ({ IconType, onClick, text, color, width }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <ClickableIcon
            isHovered={isHovered}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}>
            <StyledText style={styles.text as React.CSSProperties}>{text}</StyledText>
            <StyledIcon as={IconType} color={color} width={width} />
        </ClickableIcon>
    );
};


const styles = {
    text: {
        paddingLeft: "6px",
    },
};

const StyledText = styled.a`
        padding-left: 6px;
        text-decoration: none;
        color: black;

        @media (max-width: 992px) {
                display: none;
        }
`;

const StyledIcon = styled.div<{ color: string, width: string,}>`
    width: ${props => props.width};
    height: auto;
    color: ${props => props.color};
`;

const ClickableIcon = styled.div<{ isHovered: boolean }>`
        cursor: ${props => props.isHovered ? 'pointer' : 'unset'};
`;

export default Icon;
