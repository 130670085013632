import React from "react";
import { Container, Card, Col, Row, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Title from "../components/styledTitle";
import styled from "styled-components";
import "../styleComponents/catalogStyle.css";
import { AiOutlineZoomIn } from 'react-icons/ai';
import { ImgItem } from "../services/fetchImagesData";

type AllCatalogModalProps = {
    allImages: ImgItem[];
    handleOpenImageModal: (image: string) => void;
    handleCloseMoreModal: () => void;
    showMoreModal: boolean;
};

const AllCatalogModal: React.FC<AllCatalogModalProps> = ({ allImages, handleOpenImageModal, handleCloseMoreModal, showMoreModal }) => {
    const chunkArray = (array: ImgItem[], chunkSize: number) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    return (
        <Modal
            show={showMoreModal}
            onHide={handleCloseMoreModal}
            centered
            size="xl"
            dialogClassName="my-modal"
        >
            <div>
                <StyledModalHeader closeButton>
                    <Title text={"הגלריה המלאה שלנו"} />
                </StyledModalHeader>
            </div>
            <Modal.Body style={{ padding: 'unset' }}>
                <Container fluid>
                    {chunkArray(allImages, 3).map((row, rowIndex) => (
                        <Row key={rowIndex}>
                            {row.map((item, colIndex) => (
                                <StyledModalCol
                                    key={colIndex}
                                    xs="4"
                                    className="mb-4"
                                    style={{ margin: 0 }}
                                >
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{item.title}</Tooltip>}
                                    >
                                        <Card
                                            className="enlarge-image square-image"
                                            onClick={() => handleOpenImageModal(item.img)}
                                        >
                                            <Card.Img
                                                variant="top"
                                                alt={item.title}
                                                src={require(`../assets/images/${item.img}`)}
                                                id="img"
                                            />
                                            <div className="position-absolute top-0 start-0 p-2">
                                                <AiOutlineZoomIn size={30} color="black" />
                                            </div>
                                        </Card>
                                    </OverlayTrigger>
                                </StyledModalCol>
                            ))}
                        </Row>
                    ))}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseMoreModal}>
                    סגור
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const StyledModalHeader = styled(Modal.Header)`
  display: block;
  direction: rtl;
`;

const StyledModalCol = styled(Col)`
    padding-right: 0.5% !important;
    padding-left: 0.5% !important;
    margin-bottom: 1% !important;
    
    @media (max-width: 992px) {
        width: 100%;
    }
`;

export default AllCatalogModal;
