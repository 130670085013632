import React from "react";
import Card from "react-bootstrap/Card";
import ComponentSpacer from "../components/componentSpacer";
import styled from "styled-components";

const AboutCard: React.FC = () => {
  return (
    <AboutCardComponent>
      <ComponentSpacer space={1} />
      <Card className="text-center" style={styles.aboutCard}>
        <Card.Header style={{ backgroundColor: "transparent" }}>
          <AboutTitle>
            תיקון תיבות דואר
          </AboutTitle>
        </Card.Header>
        <Card.Body>
          <Card.Text >
            <AboutText>
              החברה שלנו היא חברה ותיקה המתמחה בשיפוץ ותיקון תיבות דואר, הכוללים -
              החלפת מנעולים ושלטים, תיקון דלתות, ניקוי, חיטוי והברקה. החברה משרתת
              את שלל לקוחותיה מכל רחבי הארץ בנאמנות ובמקצועיות רבה עם שירות מקצועי וסוגי החומר
              המשובחים והאיכותיים ביותר.
            </AboutText>
          </Card.Text>
        </Card.Body>
      </Card>
      <ComponentSpacer space={1} />
    </AboutCardComponent>
  );
};

const styles = {
  aboutCard: {
    border: "#be9827 solid",
    borderRadius: "initial",
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};

const AboutCardComponent = styled.div`
        display: grid;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
        padding-left: 5%;
        padding-right: 5%;
`;

const AboutText = styled.p`
        font-size: 1.5rem;
        line-height: 3rem;

        @media (max-width: 992px) {
          font-size: 1.2rem;
          line-height: 2rem;
        }
`;

const AboutTitle = styled.h1`
        color: #be9827;
        font-size: 2rem;
        margin-bottom: 0;

        @media (max-width: 992px) {
          font-size: 1.5rem;
        }
`;

export default AboutCard;
