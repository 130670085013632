import React from "react";

const ComponentSpacer: React.FC<{ space: number }> = ({ space }) => {
  return (
    <div>
      {[...Array(space)].map((_, index) => (
        <br key={index} />
      ))}
    </div>
  );
};

export default ComponentSpacer;
