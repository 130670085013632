// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import IconsStack from "./iconsStack";

export const Footer: FC = (): ReactElement => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "#be9827",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}
        >
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12} style={{ color: 'antiquewhite' }}>
                        <IconsStack color="antiquewhite" width="35px" />
                        <Typography style={{ textAlign: 'center', paddingTop: '1rem' }}
                            variant="subtitle1">
                            {`shalevco105@gmail.com | 052-4744470`}
                        </Typography>
                        <Typography style={{ justifyContent: 'center', display: 'flex', paddingTop: '1rem' }}
                            variant="subtitle2">
                            {`Shalev Cohen | ${new Date().getFullYear()} ©`}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;