import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import R1 from "../assets/images/R1.png";
import R2 from "../assets/images/R2.png";
import R3 from "../assets/images/R3.png";
import "../styleComponents/main.css";
import ComponentSpacer from "../components/componentSpacer";
import Title from "../components/styledTitle";

const Main: React.FC = () => {
  const [items] = useState<string[]>([R2, R1, R3]);

  return (
    <div>
      <ComponentSpacer space={4} />
      <Title text="תיקון ושיפוץ תיבות דואר" />
      <ComponentSpacer space={1} />
      <Carousel
        fade
        id="carusel"
        style={{
          margin: "auto",
        }}
      >
        {items.map((item, index) => (
          <Carousel.Item key={index}>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <img
                className="d-block w-100"
                src={item}
                alt={`Carousel item ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <ComponentSpacer space={1} />
    </div>
  );
};

export default Main;
