export const handleNavClick = (path: string) => {
    const element = document.getElementById(path);
    if (element) {
        const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: elementPosition - 58,
            behavior: "smooth",
        });
    }
};